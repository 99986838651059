@import "../../shared/shared.scss";


.movies--nav-link__more-movies {
    position: absolute;
    left: 780px;
    top: 326px;
    width: 360px;
    height: 445px;
}


.movies--nav-link__movies {
    position: absolute;
    left: 60px;
    top: 110px;
    width: 170px;
    height: 80px;
}


.movies--button__ird {
    position: absolute;
    left: 800px;
    top: 460px;
    width: 340px;
    height: 300px;
}


.movies--button__construction-timelapse {
    position: absolute;
    left: 1400px;
    top: 460px;
    width: 440px;
    height: 300px;
}


.movies--button__residences {
    position: absolute;
    left: 200px;
    top: 200px;
    width: 500px;
    height: 300px;
}


.movies--button__creation-series-1 {
    position: absolute;
    left: 1160px;
    top: 200px;
    width: 500px;
    height: 300px;
}


.movies--button__creation-series-2 {
    position: absolute;
    left: 200px;
    top: 700px;
    width: 500px;
    height: 300px;
}


.movies--button__creation-series-3 {
    position: absolute;
    left: 1160px;
    top: 700px;
    width: 500px;
    height: 300px;
}


.movies--enlarged {
    z-index: $coreMenuZIndex + 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
}


.movies--enlarged__movie {
    position: absolute;
    left: 0px;
    top: 0px;
}

.movies--enlarged__movie_gallary {
    position: absolute;
    left: 0px;
    top: 0xp;
}
.movies--enlarged__close-btn {
    position: absolute;
    top: 15px;
    right: 40px;
    color: #101b37;
    padding-top: 3px;
    font-weight: 600;
    @include font-primary;
    font-size: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    // text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    opacity: 0.6;
    transition: all 0.3s ease-out;
    &::after{
        /* Multiplication symbol (&times;) */
        content: '\00D7';
        position: absolute;
        font-family: serif;
        font-size: 1.55em;
        left: 90px;
        top: -0.28em;    
    }

    &:hover {
        opacity: 1;
    }
}

.gallery--enlarged__close-btn {
    position: absolute;
    top: 10px;
    right: 35px;
    color: #f0f1f5;
    padding-top: 3px;
    font-weight: 600;
    @include font-primary;
    font-size: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    // text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    opacity: 0.6;
    transition: all 0.3s ease-out;
    &::after{
        /* Multiplication symbol (&times;) */
        content: '\00D7';
        position: absolute;
        font-family: serif;
        font-size: 1.55em;
        left: 90px;
        top: -0.28em;    
    }

    &:hover {
        opacity: 1;
    }

}
