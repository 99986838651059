
@import "../../shared/shared.scss";


.aerial-map--transform-container {
    position: absolute;
    top: 100px;
    left: 0;
    width: #{( 1920 - 150 )}px;
    // width: #{( 1920 - 150 )}px;
    height: 980px;
}

.aerial-map--frg {
    position: absolute;
}


.aerial-map--frg {
    position: absolute;
    left: 0;
    top: 0;
    height: 980px;
}


.aerial-map--menu {
    position: absolute;
    top: 0px;
    width: 385px;
    height: 980px;
    right: -150px;
    background: $bkgColour;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);    

    

}


    .aerial-map--menu-options {
        position: absolute;
        top: 0px;
        right: 80px;
        left: 70px;
        width: 100%;
    }


.aerial-map--title:first-child {
    position: relative;
    left: -28px;
    display: block;
    margin: 40px 0px 25px 0px;
    color: #e8e3dc;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @include font-secondary;
}

.aerial-map--title:nth-of-type(2){
    position: relative;
    left: -28px;
    display: block;
    margin: 60px 0px 25px 0px;
    color: #e8e3dc;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @include font-secondary;
}





.aerial-map--btn {
    position: relative;
    display: block;
    margin: 10px 0px 30px 0px;
    padding:17px 0px 10px 35px;
    color: $highlightTextColour;
    font-size: 19px;
    text-align: left;
    @include font-primary;
    img {
        position: absolute;
        left: -30px;
        top: 3px;
    }
    transition: all 0.2s ease-out;
		&:hover {
			color: $primaryTextColour;
		}
}


    .aerial-map--btn__active {
        @extend .aerial-map--btn;
        color: $primaryTextColour;
    }


.aerial-map--zoom-tools {
    z-index: $coreMenuZIndex - 2;
    position: absolute;
    left: 30.5%;
    font-family: 'Brown';
    top: 800px;
    height: 40px;
    text-align: center;
    transform: translateX(-70%);

    button {
        margin: 0px 10px;
        cursor: pointer;
        transition: all 0.2s ease-out;
		&:hover {
			color: $primaryTextColour;
		}
    }
}

.aerial-map--zoom-tools--title {
    margin: 10px auto 20px;
    @include font-secondary;
    text-transform: uppercase;
    color: #f7a8ae;
    font-size: 16px;
}