
@import '../../shared/shared.scss';


.amenities--levels {
    position: absolute;
    top: 0px;
    left: 0px;
}

.amenities-hide {
    display: none;
}

.heading-title {
    margin-left: -45px;
    @include font-secondary;
    font-size: 22px;
    letter-spacing: 0.05em;
    padding-bottom: 10px;
}

.menu-ui-text {
    font-size: 18px;
}

.menu-signature {
    font-size: 18px;
}
.amenities--ui {
    position: absolute;
    width: 420px;
    height: 1080px;
    right: -30px;
    top: -30px;
    padding-top: 305px;
    padding-left: 100px;
    ul {
        margin: 0px;
        padding: 0px;
    }

    
    li {
        position: relative;
        margin: 0;
        margin-bottom: 45px;
        height: 20px;
        padding: 0px;
        list-style-type: none;
        z-index: 0;

        &:before {
            content: '';
            position: absolute;
            top: -4px; right: 320px;
            width: 50px; height: 50px;

            z-index: -1;
        }
        button {
            margin-left: -50px;

            span {
                margin-left: 20px;
                color: black;
                @include font-secondary;
            }

        }
    }
    li:nth-of-type(2) {
        button {
            margin-left: -50px;

            span {
                margin-left: 20px;
            }

        }
        position: relative;
        margin: 0;
        margin-bottom: 49px;
        top: 110px;
        height: 20px;
        padding: 0px;
        list-style-type: none;
        z-index: 0;
        &:before {
            content: '';
            position: absolute;
            top: -4px; right: 320px;
            width: 50px; height: 50px;

            z-index: -1;
        }
       
    
    }
    li:nth-of-type(3),
    li:nth-of-type(4),
    li:nth-of-type(5) {
        button {
            margin-left: -50px;

            span {
                margin-left: 20px;
            }

        }
        position: relative;
        margin: 0;
        margin-bottom: 49px;
        top: 110px;
        height: 20px;
        padding: 0px;
        list-style-type: none;
        z-index: 0;
        &:before {
            content: '';
            position: absolute;
            top: -4px; right: 320px;
            width: 50px; height: 50px;

            z-index: -1;
        }
       
    
    }
    
}


    .amenities--ui__btn {
        min-height: 20px;
        margin: 0;
        padding: 0;
        right: -180px;
        color: white;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: normal;
        @include font-primary;
        &:hover {
            font-family: 'Brown';
            font-weight: 800;
            color: white;
        }
        
    }


    .amenities--ui__btn-active {
        @extend .amenities--ui__btn;
        font-family: 'Brown';
        font-weight: 800;
        color: white;
        &:hover {
            font-family: 'white';
            font-weight: 800;
        }
    }


    .amenities--ui__levels-dragger {
        position: absolute;
        z-index: 3;
        left: 5px;
        top: 1px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $bkgColour;
        cursor: pointer;
        transition: all 0.3s ease-out;
        &:hover {
            background: $bkgColour;
            color: red;
        }
    }	


.amenities--hotspots-container {
    position: absolute;
    left: 0px;
    top: 0px;
}


.amenities--hotspot {
    position: absolute;
}


    .amenities--hotspot__btn {
        width: 60px;
        height: 220px;
        background: url(./residences-hotspot-15.png) no-repeat;
        background-size: 60px 220px;
    }

    .amenities--hotspot__btn_nested {
        width: 40px;
        height: 40px;
        background: url(./icon--image.png) no-repeat;
        background-size: 40px 40px;
    }


    .amenities--hotspot__tmb {
        display: none; //show with JS
        position: absolute;

        &, &.bottom-right {
            left: 55px;
            top: 90px;
        }

        &.bottom-right2 {
            left: 45px;
            top: 270px;
        }

        &.bottom-left {
            right: 45px;
            left: unset;
            top: 0px;
        }

        &.top-right {
            left: 70px;
            top: -220px;
        }

        &.top-left {
            right: 45px;
            left: unset;
            bottom: 0px;
            top: unset;
        }
    } 

    // .enlarged--close-btn-amenities {
    //     position: absolute;
    //     width: 200px;
    //     height: 200px;
    //     right: 700px;
    //     top: 300px;
    //     background-color: red;
    //     font-size: 40px;
    // }



.enlarged--close-btn-amenities-black {
    position: absolute;
    top: 15px;
    right: 40px;
    color: #101b37;
    padding-top: 3px;
    font-weight: 600;
    @include font-primary;
    font-size: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    // text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    opacity: 0.6;
    transition: all 0.3s ease-out;
    &::after{
        /* Multiplication symbol (&times;) */
        content: '\00D7';
        position: absolute;
        font-family: serif;
        font-size: 1.55em;
        left: 90px;
        top: -0.28em;    
    }

    &:hover {
        opacity: 1;
    }
}

.enlarged--close-btn-amenities-black-top {
    position: absolute;
    top: 15px;
    right: 40px;
    color: #101b37;
    padding-top: 3px;
    font-weight: 600;
    @include font-primary;
    font-size: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    // text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    opacity: 0.6;
    transition: all 0.3s ease-out;
    &::after{
        /* Multiplication symbol (&times;) */
        content: '\00D7';
        position: absolute;
        font-family: serif;
        font-size: 1.55em;
        left: 90px;
        top: -0.28em;    
    }

    &:hover {
        opacity: 1;
    }
}
.enlarged--close-btn-amenities-white {
    position: absolute;
    top: 15px;
    right: 40px;
    color: #101b37;
    padding-top: 3px;
    font-weight: 600;
    @include font-primary;
    font-size: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    // text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    opacity: 0.6;
    transition: all 0.3s ease-out;
    &::after{
        /* Multiplication symbol (&times;) */
        content: '\00D7';
        position: absolute;
        font-family: serif;
        font-size: 1.55em;
        left: 90px;
        top: -0.28em;    
    }

    &:hover {
        opacity: 1;
    }
}


.left-arrow {
    position: absolute;
    transform: rotate(180deg);
    top: 450px;
    color: #101b37;
    transform: rotate(270deg);
    padding: 30px;
}

.right-arrow {
    position: absolute;
    top: 460px;
    right: 0;
    color: #101b37;
    transform: rotate(90deg);
    padding: 30px;



}