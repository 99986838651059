
@import "../../../shared/shared.scss";

.overlay {
    z-index: $enlargedZIndex;
    position: fixed;
    top: $coreMenuHeight;
    left: 0;
    width: 100%;
    height: calc(100% - #{$coreMenuHeight});
    color: white;

    &.overlay {
        &__shaded {
            background: rgba(232, 227, 220, 0.7);
        }

        &__full-screen {
            top: 0;
            height: 100%;
        }
    }
}

.overlary--menu-cover {
    position: absolute;
    top: -$coreMenuHeight; left: 0;
    width: 100%;
    height: $coreMenuHeight;
}

.enlarged--close-btn {
    position: absolute;
    top: 15px;
    right: 40px;
    color: #101b37;
    padding-top: 3px;
    font-weight: 600;
    @include font-primary;
    font-size: 19px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    // text-shadow: 0 0 6px rgba(0, 0, 0, 1);
    opacity: 0.6;
    transition: all 0.3s ease-out;
    &::after{
        /* Multiplication symbol (&times;) */
        content: '\00D7';
        position: absolute;
        font-family: serif;
        font-size: 1.55em;
        left: 90px;
        top: -0.28em;    
    }

    &:hover {
        opacity: 1;
    }
}


// .enlarged--close-btn {
//     position: absolute;
//     top: 20px;
//     right: 50px;
//     color: white;

//     @include font-primary;
//     font-size: 18px;
//     letter-spacing: 0.3em;
//     text-transform: uppercase;
//     text-shadow: 0 0 6px rgba(0, 0, 0, 1);
//     opacity: 0.6;
//     transition: all 0.3s ease-out;

//     &::after{
//         /* Multiplication symbol (&times;) */
//         content: '\00D7';
//         position: absolute;
//         font-family: serif;
//         font-size: 2em;
//         right: -30px;
//         top: -0.3em;
//     }

//     &:hover {
//         opacity: 1;
//     }
// }