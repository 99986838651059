@import "../../shared/shared.scss";

$fontSize: 16px;

$linkHeight: $fontSize * 1.5;
$linkOffset: 0px;

$logoHeight: 100px;
$logoOffset: 0px;

.header {
    z-index: $coreMenuZIndex;
    position: fixed;
    top: 0;
    width: 100%;
    height: $coreMenuHeight;
    background: $bkgColour;
    border-bottom: 0 !important;
}


.header--nav {
    position: absolute;
    top: 0px;
    left: 40px;

    ul {
        margin: 0;
        padding: 0;
    }
}


.header--nav__li {
    position: relative;
    display: inline-block;
    margin: 0px 80px 0px 0px;
    vertical-align: middle;
    //box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);  

    &:first-child {
        margin-right: 100px;
    }

    &:last-child {
        margin-right: 0px;
    }
}

.header--nav__sub-menu {
    display: none; //displayed with JS
    position: absolute;
    left: 0%;
    top: 100%;
    width: 100%;
    padding: 0px;
    background: $bkgColour;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    margin-top: 18px;
    filter: drop-shadow(3px 3px 3px rgb(31, 31, 31));

    //&:nth-child(6) {
    //    left: 0px;
    //}

}


.header--nav__link {
    $linkPaddingTop: (($coreMenuHeight - $linkHeight) / 2)+$linkOffset;
    $linkPaddingBottom: (($coreMenuHeight - $linkHeight) / 2) - $linkOffset;

    display: block;
    margin: 0px;
    padding: #{ $linkPaddingTop } 31px #{ $linkPaddingBottom };
    // padding: 40px 25px;
    color: $highlightTextColour;
    text-transform: uppercase;
    text-decoration: none;
    @include font-primary;
    transition: all 0.3s ease-out;
    font-size: $fontSize;

    &:hover {
        color: white;

        text-decoration: underline;
        text-underline-offset: 6px;
    }
}

.header--nav__link-selected {
    @extend .header--nav__link;
    text-decoration: underline;
    text-underline-offset: 6px;
    color: white;
    &:hover {
        color: white;

        text-decoration: underline;
        text-underline-offset: 6px;
    }
}


.header--nav__link-in-sub-menu {
    @extend .header--nav__link;
    height: auto;
    padding: 15px 15px;
}


.header--nav__link-logo {
    $logoPaddingTop: (($coreMenuHeight - $logoHeight) / 2)+$logoOffset;
    $logoPaddingBottom: (($coreMenuHeight - $logoHeight) / 2) - $logoOffset;
    padding-left: 30px;
    @extend .header--nav__link;
    padding-top: #{ $logoPaddingTop };
    padding-bottom: #{ $logoPaddingBottom };

    img {
        max-height: $logoHeight;
        height: $logoHeight;
        width: auto;
    }
}